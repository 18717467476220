import React from "react"
import PropTypes from "prop-types"
import parse from "html-react-parser"
import { graphql, useStaticQuery } from "gatsby"

function KeepInMind({ text }) {
  const { allPrismicConfig } = useStaticQuery(graphql`
    query KeepInMindConfig {
      allPrismicConfig {
        edges {
          node {
            data {
              topic_page_keep_in_mind_title {
                text
              }
            }
          }
        }
      }
    }
  `)

  const { topic_page_keep_in_mind_title } = allPrismicConfig.edges[0].node.data

  return (
    <React.Fragment>
      <h3 className=" text-grey-darker font-semibold leading-snug text-2xl mb-2 flex-grow">
        {topic_page_keep_in_mind_title?.text ??
          "Need help navigating these tasks?"}
      </h3>
      <div className="body-text p-m-0 text-grey-dark">{parse(text)}</div>
    </React.Fragment>
  )
}

KeepInMind.propTypes = {
  text: PropTypes.string.isRequired,
}

export default KeepInMind

import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { useUser } from "../../context/user-context"
import { useQuery } from "@apollo/client"
import { GET_CHECKLIST } from "../../graphql/queries/checklist"
import { QUERY_GET_COLLABORATORS } from "../../graphql/queries/team"
import { getTopicWithSlug } from "../../utils/topics"
import parse from "html-react-parser"
import SidebarLayout from "../../components/sidebar/layout"
import LoadingAnimation from "../../components/loading-animation"
import KeepInMind from "../../components/keep-in-mind"
import AddTaskModal from "../../components/add-task-modal"
import UpgradeFlow from "../../components/checkout/upgrade-flow"
import UpgradePaywallModal from "../../components/checkout/upgrade-paywall-modal"
import Task from "../../components/task/task"
import Navigation from "../../components/navigation"
import TasksSearch from "../../components/search/tasks-search"
import NoResults from "../../components/search/no-results"
import { TransitionGroup, CSSTransition } from "react-transition-group"

const TopicPage = ({ planId, topicSlug }) => {
  const [isAddTaskModalVisible, setIsAddTaskModalVisible] = useState(false)
  const [isUpgradeFlowVisible, setIsUpgradeFlowVisible] = useState(false)
  const [allTopics, setAllTopics] = useState(null)
  const [currentTopic, setCurrentTopic] = useState(null)
  const [prevTopic, setPrevTopic] = useState(null)
  const [nextTopic, setNextTopic] = useState(null)
  const [hasNext, setHasNext] = useState(null)
  const [hasPrevious, setHasPrevious] = useState(null)
  const [resultCount, setResultCount] = useState(null)
  const [searchResults, setSearchResults] = useState(null)
  const [plan, setPlan] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false)
  const user = useUser()

  // @TODO - This data should be passed in a prop so that we don't need to make an extra request
  const { data, loading, refetch } = useQuery(GET_CHECKLIST, {
    variables: { id: planId },
  })

  const { data: collaborators } = useQuery(QUERY_GET_COLLABORATORS, {
    variables: { plan_id: planId },
  })

  useEffect(() => {
    if (data) {
      // get topics and plan from checklist.
      const { topics, plan } = data.checklist
      setAllTopics(topics)
      setPlan(plan)

      let { prevTopic, nextTopic, topic, hasNext, hasPrevious } =
        getTopicWithSlug(topics, topicSlug)

      setPrevTopic(prevTopic)
      setNextTopic(nextTopic)
      setCurrentTopic(topic)
      setHasNext(hasNext)
      setHasPrevious(hasPrevious)

      if (plan && collaborators) {
        setIsAdmin(
          user.id === plan.user_id ||
            !collaborators?.getCollaborators?.team_members?.filter(
              (member) =>
                member.role === "Viewer" && member.email === user.email
            ).length > 0
        )
      }
    }
  }, [data, topicSlug])

  const renderAllCategoriesForTopic = (categories) => {
    const doneCategory = { name: "Done", tasks: [] }
    const categoriesWithDoneTaskRemoved = categories
      .map((category) => {
        return {
          ...category,
          tasks: category.tasks.filter((task) => {
            if (task.is_completed) {
              doneCategory.tasks.push(task)
              return false
            }
            return true
          }),
        }
      })
      .filter((category) => category?.tasks?.length > 0)
    const allCategories = categoriesWithDoneTaskRemoved.concat(
      doneCategory.tasks.length > 0 ? [doneCategory] : []
    )

    return (
      <TransitionGroup>
        {allCategories.map((category) => (
          <CSSTransition
            key={category.name}
            timeout={500}
            classNames="category-transition"
          >
            <div>
              <h4 className="text-2xl md:text-3xl font-extrabold w-full pt-8 pb-4 leading-tight">
                {category.name}
              </h4>
              <TransitionGroup
                component={category.name === "Done" ? "ul" : "div"}
              >
                {category.tasks.map((task) => (
                  <CSSTransition
                    key={task.prismic_provided_task_id}
                    timeout={500}
                    classNames="task-transition"
                  >
                    <Task
                      isAdmin={isAdmin}
                      task={task}
                      topic={currentTopic}
                      showPaywallModal={() => {
                        setIsUpgradeFlowVisible(true)
                      }}
                      refetch={() => refetch()}
                    />
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </div>
          </CSSTransition>
        ))}
      </TransitionGroup>
    )
  }

  const renderSearchResults = (results) => {
    return results.map(
      (result) =>
        result?.tasks?.length > 0 && (
          <React.Fragment key={result.topicName}>
            <h2
              key={result.topicName}
              className="text-2xl md:text-3xl font-extrabold w-full pt-12 pb-2"
            >
              {result.topicName}
            </h2>
            {result.tasks?.map((task) => (
              <Task
                task={task}
                files={data.checklist.files}
                key={task.id}
                isAdmin={isAdmin}
                refetch={() => refetch()}
                topic={result.topic}
              />
            ))}
          </React.Fragment>
        )
    )
  }

  if (loading || !currentTopic) {
    return (
      <SidebarLayout>
        <LoadingAnimation />
      </SidebarLayout>
    )
  }

  // NoResults props
  const callToAction = (
    <div className="flex flex-row">
      <p>If you&apos;re not able to find the task you need, try</p>
      <div
        onClick={() => setIsAddTaskModalVisible(true)}
        className="text-primary underline ml-1 cursor-pointer"
      >
        adding your own.
      </div>
    </div>
  )

  return (
    <SidebarLayout fullWidth fullHeight planId={planId} topicSlug={topicSlug}>
      <div className="min-h-full">
        <div className="lantern-container mb-12">
          <div className="flex flex-col md:flex-row">
            <div className="flex flex-row">
              <h1 className="text-4xl md:text-5xl leading-tight font-extrabold w-full pt-6 md:pt-12 pb-12">
                {currentTopic.title}
              </h1>
            </div>
            {/* @TODO - This should use the Button component */}
            {(currentTopic.is_ungated || user?.is_subscribed) && (
              <div className="flex flex-wrap content-center justify-center md:justify-end md:ml-2 flex-grow">
                <div
                  onClick={() => setIsAddTaskModalVisible(true)}
                  className="relative btn out-of-app -primary flex items-center justify-center"
                  role="button"
                >
                  <div className="flex items-center justify-center">
                    <i className="relative material-icons text-lg text-grey-darker">
                      add
                    </i>
                    <span className="font-semibold font-sm whitespace-nowrap">
                      Add Task
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="mt-12 md:mt-0">
            <TasksSearch
              allTopics={allTopics}
              setResults={setSearchResults}
              setResultCount={setResultCount}
            />
          </div>
          {!user.is_subscribed &&
            currentTopic?.is_ungated &&
            currentTopic?.description && (
              <div className="mt-4 bg-secondary-light rounded-xl px-8 py-8 md:px-20 md:py-12 body-text p-m-0 inline-inner">
                {parse(currentTopic.description)}, click
                <span
                  className="cursor-pointer underline text-primary"
                  onClick={() => setIsUpgradeFlowVisible(true)}
                >
                  Upgrade
                </span>
                .
              </div>
            )}

          {resultCount === 0 ? (
            <NoResults from="tasks" callToAction={callToAction} />
          ) : resultCount > 0 ? (
            renderSearchResults(searchResults)
          ) : (
            renderAllCategoriesForTopic(currentTopic.categories)
          )}
        </div>

        {currentTopic.keep_in_mind_text && (
          <div className="lantern-container mt-16 mb-12">
            <div className="bg-secondary-light rounded-xl p-6 md:p-8 border border-grey-light">
              <KeepInMind text={currentTopic.keep_in_mind_text} />
            </div>
          </div>
        )}
        <div className="lantern-container pb-24">
          <Navigation
            hasPrevious={hasPrevious}
            hasNext={hasNext}
            prevTitle={prevTopic.title}
            nextTitle={nextTopic.title}
            prevLink={`/app/plan/${plan.id}/topic/${prevTopic.uid}`}
            nextLink={`/app/plan/${plan.id}/topic/${nextTopic.uid}`}
          />
        </div>
      </div>

      {isAddTaskModalVisible && (
        <AddTaskModal
          plan={plan}
          closeModal={() => setIsAddTaskModalVisible(false)}
          topic={currentTopic}
          topics={allTopics}
          showPaywallModal={() => {
            setIsAddTaskModalVisible(false)
            setIsUpgradeFlowVisible(true)
          }}
          refetch={() => refetch()}
        />
      )}

      {isUpgradeFlowVisible && (
        <UpgradeFlow
          paywallComponent={UpgradePaywallModal}
          onCancel={() => setIsUpgradeFlowVisible(false)}
          onSuccess={() => {
            setIsUpgradeFlowVisible(false)
          }}
        />
      )}
    </SidebarLayout>
  )
}

TopicPage.propTypes = {
  planId: PropTypes.string.isRequired,
  topicSlug: PropTypes.string.isRequired,
}

export default TopicPage

import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

function Navigation({
  prevLink,
  nextLink,
  nextTitle,
  prevTitle,
  hasPrevious,
  hasNext,
}) {
  const paginatePrevious = (e) => {
    if (!hasPrevious) e.preventDefault()
  }

  const paginateNext = (e) => {
    if (!hasNext) e.preventDefault()
  }

  return (
    <div className="flex items-center justify-between">
      <div>
        {prevLink && hasPrevious && (
          <Link to={prevLink} onClick={paginatePrevious}>
            <div
              className={`flex items-center group ${
                hasPrevious
                  ? "text-primary"
                  : "text-base text-grey font-semibold hover:text-primary"
              }`}
            >
              <div
                className="rounded-full h-10 w-10 md:h-12 md:w-12 flex items-center 
              justify-center border border-grey-lighter group-hover:border-primary"
              >
                <span className="material-icons focus:ring-4">arrow_back</span>
              </div>

              {prevTitle && (
                <span className="pl-3 hidden sm:block cursor-pointer">
                  {prevTitle}
                </span>
              )}

              {!hasNext && (
                <span className="pr-3 block sm:hidden cursor-pointer">
                  {prevTitle}
                </span>
              )}
            </div>
          </Link>
        )}
      </div>

      <div>
        {nextLink && hasNext && (
          <Link to={nextLink} onClick={paginateNext}>
            <div
              className={`flex items-center group ${
                hasNext
                  ? "text-primary"
                  : "text-base text-grey font-semibold hover:text-primary"
              }`}
            >
              {nextTitle && (
                <span className="pr-3 cursor-pointer">{nextTitle}</span>
              )}
              <div
                className="rounded-full h-10 w-10 md:h-12 md:w-12 flex items-center 
              justify-center border border-grey-lighter group-hover:border-primary"
              >
                <span className="material-icons focus:ring-4">
                  arrow_forward
                </span>
              </div>
            </div>
          </Link>
        )}
      </div>
    </div>
  )
}

Navigation.propTypes = {
  prevLink: PropTypes.string,
  nextLink: PropTypes.string,
  prevTitle: PropTypes.string,
  nextTitle: PropTypes.string,
  hasPrevious: PropTypes.bool,
  hasNext: PropTypes.bool,
}

export default Navigation

import React from "react"
import PropTypes from "prop-types"

const NoResults = ({ searchState, from, callToAction }) => {
  const noResults = (
    <p>{`No ${from} found for your search term ${
      searchState ? `"${searchState}"` : ""
    }`}</p>
  )

  return (
    <div className={`px-1 pb-10 ${from === "tasks" && "mt-12"}`}>
      {noResults}
      {callToAction}
    </div>
  )
}

NoResults.propTypes = {
  searchState: PropTypes.string,
  from: PropTypes.string,
  callToAction: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

NoResults.defaultProps = {
  searchState: null,
  from: "",
  callToAction: "",
}

export default NoResults

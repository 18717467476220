export const getTopicWithSlug = (topics, topicSlug) => {
  const topicsCount = topics.length
  let prevTopic, nextTopic, topic
  let hasNext = true
  let hasPrevious = true
  let setIndex = 0

  for (let i = 0; i < topicsCount; i++) {
    prevTopic = topics[i === 0 ? topicsCount - 1 : i - 1]
    if (topics[i].uid === topicSlug) {
      topic = topics[i]
      nextTopic = topics[i === topicsCount - 1 ? 0 : i + 1]
      setIndex = i
      break
    }
  }

  if (setIndex === 0) {
    hasPrevious = false
  }

  if (setIndex === topicsCount - 1) {
    hasNext = false
  }
  return { prevTopic, nextTopic, topic, hasPrevious, hasNext, setIndex }
}
